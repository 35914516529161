import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import DataService from "../services/data.service";

const Resetpassword = (props) => {
   const form = useRef();
   const params = useParams();
   const navigate = useNavigate();
   const [password, setPassword] = useState("");
   const [confirmPassword, setConfirmPassword] = useState("");
   const [loading, setLoading] = useState(false);
   const [passwordType, setPasswordType] = useState("password");
   const [passwordType2, setPasswordType2] = useState("password");

   React.useEffect(() => {
      document.title = "Reset Password";
   }, []);

   const onChangePassword = (e) => {
      const data = e.target.value;
      setPassword(data);
   }
   const onChangeConfirmPassword = (e) => {
      const data = e.target.value;
      setConfirmPassword(data);
   }

   const togglePassword = () => {
      if (passwordType === "password") {
         setPasswordType("text")
         return;
      }
      setPasswordType("password")
   }

   const togglePassword2 = () => {
      if (passwordType2 === "password") {
         setPasswordType2("text")
         return;
      }
      setPasswordType2("password")
   }

   const navigateToNextPage = async (e) => {
      e.preventDefault();
      if (password === confirmPassword) {
         setLoading(true);
         const data = {};
         data.password = password;
         data.reset_token = params.token;
         await DataService.resetPassword(data).then(
            () => {
               setLoading(false);
               toast.success('Password changed successfully! Please Login Now', {
                  position: toast.POSITION.TOP_RIGHT
               });
               setTimeout(() => {
                  navigate('/login')
               }, 1500);
            },
            (error) => {
               const resMessage = error?.response?.data?.msg
               setLoading(false);
               toast.error(resMessage, {
                  position: toast.POSITION.TOP_RIGHT
               });
            });
      } else {
         toast.error("Passwords are not same!!")
      }
   }

   return (
      <div className="row align-items-center justify-content-between w-100">
         <div className="col-sm-6">
            <div class="pop">
               <Link to={"/"}>
                  <img src="../assets/img/adminlogo.png" className="main_logo mb-4" alt="" srcset="" />
               </Link>
               <h5 class="mb-4">Reset your password</h5>
               <form onSubmit={navigateToNextPage} ref={form} class="mt-3 w-100">
                  <div class="d-flex flex-column gap-3">
                     <div className="pr password-wrap password-wrap-reset mb-2">
                        <input
                           type={passwordType}
                           required
                           onChange={onChangePassword}
                           className="form-control"
                           placeholder="New password"
                           autocomplete="off"
                        />
                        {passwordType === "password"
                           ?
                           <i class="fas fa-eye" onClick={togglePassword}></i>
                           :
                           <i class="fas fa-eye-slash" onClick={togglePassword}></i>
                        }
                     </div>
                     <div className="pr password-wrap password-wrap-reset mb-2">
                        <input
                           type={passwordType2}
                           required
                           onChange={onChangeConfirmPassword}
                           className="form-control"
                           placeholder="Re-enter new password"
                        />
                        {passwordType2 === "password"
                           ?
                           <i class="fas fa-eye" onClick={togglePassword2}></i>
                           :
                           <i class="fas fa-eye-slash" onClick={togglePassword2}></i>
                        }
                     </div>
                     <button type='submit' className="btn btn-primary bg-yellow" disabled={loading}>
                        {loading && (
                           <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Reset Password</span>
                     </button>
                     <p class="text-center m-0 text-dark">
                        Don't want to reset ?
                        <Link to={"/login"} class="signup">
                           {" "}Login</Link>
                     </p>
                  </div>
               </form>
            </div>
         </div>
         <div className="col-sm-6">
            <img src="../assets/img/dealit-vendorLogin.jpeg" style={{ objectFit: 'cover' }} className="w-100 h-100" alt="reset-password" />
         </div>
      </div>
   );
};
export default Resetpassword;
