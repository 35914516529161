import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import DataService from "../../../services/data.service";
import "../../../CustomCss/company.css";


const CompanyList = () => {
    const [comapny, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredData, setfilteredData] = useState([]);
    //console.log(props)

    useEffect(() => {
        document.title = "Comapny";
        setLoading(false);

        // getCategory(); 
    }, []);

    const getCategory = () => {
        DataService.getAllBrand().then((data) => {
            setData(data.data.data);
            setfilteredData(data.data.data);
            setLoading(false);
        });
    };
    const onChangeSearch = (e) => {
        if (e.target.value) {
            const result = comapny.filter((value) => {
                return (
                    value.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
                    value.description.toLowerCase().includes(e.target.value.toLowerCase())
                );
            });
            setfilteredData(result);
        } else {
            setfilteredData(comapny);
        }
    };
    const onChangeStatus = (e) => {
        console.log(e.target.value);
        if (e.target.value !== "All") {
            const result = comapny.filter((value) => {
                return value.status === e.target.value;
            });
            setfilteredData(result);
        } else {
            setfilteredData(comapny);
        }
    };
    return (


        <div class="container">

    <div class="check-head my-4">
        <h3>Manage Company Information</h3>
    </div>

    <form class="checkbox">
        <div class="row check-row">
            <div class="col-md-4">
                <div class="main-label text-end">
                    <h2>Business Type:</h2>
                </div>
            </div>
            <div class="col-md-8 p-0">
                <div class="form-check checkbox">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked1" />
                    <label class="form-check-label" for="flexCheckChecked1">
                      Manufacturer
                    </label>
                  </div>
                <div class="form-check checkbox">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked2" />
                    <label class="form-check-label" for="flexCheckChecked2">
                      Trading Company
                    </label>
                </div>
                <div class="form-check checkbox">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked3" />
                    <label class="form-check-label" for="flexCheckChecked3">
                      Buying Office
                    </label>
                </div>
                <div class="form-check checkbox">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked4" />
                    <label class="form-check-label" for="flexCheckChecked4">
                      Agent
                    </label>
                </div>
                <div class="form-check checkbox">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked5" />
                    <label class="form-check-label" for="flexCheckChecked5">
                      Distributor/Wholesaler
                    </label>
                </div>
                <div class="form-check checkbox">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked6" />
                    <label class="form-check-label" for="flexCheckChecked6">
                      Government Ministry/Bureau/Commission
                    </label>
                </div>
                <div class="form-check checkbox">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked7" />
                    <label class="form-check-label" for="flexCheckChecked7">
                      Association
                    </label>
                </div>
                <div class="form-check checkbox">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked8" />
                    <label class="form-check-label" for="flexCheckChecked8">
                      Business Service(Transportation,finance,travel,Ads.etc)
                    </label>
                </div>
                <div class="form-check checkbox">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked9" />
                    <label class="form-check-label" for="flexCheckChecked9">
                      Other
                    </label>
                </div>
                <p>Select up to 3 Business Type</p>
            </div>
            <div class="check-btn">
                <Link to="/manage-company-info">Next</Link>

            </div>
        </div>
    </form>



          </div>



    );
};

export default CompanyList;
