import React, { useState, useMemo, useEffect } from 'react'
import Header from '../common/Header'
import Footer from '../common/Footer'
import Sidebar from '../common/sidebar'
import CountryList from 'react-select-country-list';
import DataService from '../services/data.service';
import '../CustomCss/BankAccount.css'
import categories from '../StaticData/MccList';
import Swal from 'sweetalert2'
import {
    Autocomplete,
    Grid,
    Select,
    Checkbox,
    ListItemText,
    ListItem,
    MenuItem,
    FormControl,
    InputLabel,
    TextField,
} from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { toast } from 'react-toastify';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const CompanyAccount = () => {
    const [bankData, setBankData] = useState(false)
    const [profileData, setProfileData] = useState(false);
    const [allData, setAllData] = useState([]);
    const [companyForm, setCompanyForm] = useState(false);
    const [bankForm, setBankForm] = useState(false);
    const [profileForm, setProfileForm] = useState(false);
    const [allSubmissions, setAllSubmissions] = useState(true)
    const getStripeData = (value) => {
        const data = { account_id: value };
        setLoading(true);
        DataService.getStripeData(data).then(
            (res) => {
                setAllData(res?.data)
                setLoading(false);
                setCompanyForm(true)
                setSelectedCountryCode(res?.data?.country || "")
                setCompanyEmail(res?.data?.email || 0)
                setBusinessUrl(res?.data?.business_profile?.url || "")
                setCompanyName(res?.data?.company?.name || "")
                setTaxId(res?.data?.company?.tax_id || "")
                setPhoneCode(res?.data?.company?.phone || "")
                setSelectedCity(res?.data?.company?.address?.city || "")
                setLine(res?.data?.company?.address?.line1 || "")
                setPostalCode(res?.data?.company?.address?.postal_code || "")
                if (res?.data?.external_accounts?.[0]?.id) {
                    setBankData(true);
                    setBankForm(true)
                    setAccountNumber("****" + res?.data?.external_accounts?.[0]?.last4)
                    setAccountName(res?.data?.external_accounts?.[0]?.bank_name)
                    setCfAccountNumber("****" + res?.data?.external_accounts?.[0]?.last4)
                    setRoutingNumber(res?.data?.external_accounts?.[0]?.routing_number)
                }
                if (res?.data?.profiles?.length > 0) {
                    setProfileData(true);
                    setProfileForm(true)
                    setProfLine(res?.data?.profiles?.[0]?.address?.line1)
                    setProfPostalCode(res?.data?.profiles?.[0]?.address?.postal_code)
                    setProfCity(res?.data?.profiles?.[0]?.address?.city)
                    setFirstName(res?.data?.profiles?.[0]?.first_name)
                    setLastName(res?.data?.profiles?.[0]?.last_name)
                    setProEmail(res?.data?.profiles?.[0]?.email)
                    setProPhoneCode(res?.data?.profiles?.[0]?.phone)
                }
            },
            (error) => {
                setLoading(false);
                const resMessage = error?.response?.data?.msg;
                toast.error(resMessage, { position: toast.POSITION.TOP_RIGHT });
            }
        );
    };
    useEffect(() => {
        if (countries.length > 0) {
            const selectedsCountry = countries?.find((item) => item.iso2 === allData?.country);
            if (selectedsCountry) {
                setSelectedCountry(selectedsCountry);
            }
        }
        if (mccList.length > 0) {
            const selMcc = mccList?.find((item) => item.category_code === allData?.business_profile?.mcc);
            if (selMcc) {
                setSelectedMcc(selMcc)
            }
        }
        if (states.length > 0) {
            const selState = states?.find((item) => item.iso2 === allData?.company?.address?.state);
            if (selState) {
                setSelectedState(selState)
            }
        }
        if (allData?.external_accounts?.[0]?.id) {
            const selectedBCountry = countries?.find((item) => item.iso2 === allData?.external_accounts?.[0]?.country);
            if (selectedBCountry) {
                setSelectedBankCountry(selectedBCountry);
            }

        }
        if (allData?.profiles?.length > 0) {
            if (countries.length > 0) {
                const profCountry = countries?.find((item) => item.iso2 === allData?.profiles?.[0]?.address?.country);
                if (profCountry) {
                    setProfSelectedCountry(profCountry)
                }
            }
        }
        if (allData?.profiles?.length > 0) {
            if (proStatesList.length > 0) {
                const profState = proStatesList?.find((item) => item.iso2 === allData?.profiles?.[0]?.address?.state);
                if (profState) {
                    setProfSelectedState(profState)
                }
            }
        }
        if (allData?.profiles?.length > 0) {
            if (genderOptions.length > 0) {
                const userGender = genderOptions?.find((item) => item.value === allData?.profiles?.[0]?.gender);
                if (userGender) {
                    setGender(userGender)
                }
            }
        }
        if (allData?.profiles?.length > 0) {
            if (roleOptions.length > 0) {
                const userRole = roleOptions?.find((item) => item.value === allData?.profiles?.[0]?.gender);
                if (userRole) {
                    setRole(userRole)
                }
            }
        }

        const mappedRoles = roleOptions.filter(option => allData?.profiles?.[0]?.relationship?.[option.value] === "1");
        setRole(mappedRoles);
        if (allData?.profiles?.length > 0) {
            const { day, month, year } = allData?.profiles?.[0]?.dob;
            const prefilledDate = new Date(year, month - 1, day);
            setDateOfBirth(prefilledDate);
            setDob({ day, month, year });
        }
        if (allData && allData?.external_accounts?.[0]?.id) {
            setAllSubmissions(false)
        }
    }, [allData])

    const [companyDetails, setCompanyDetails] = useState(true);
    const [bankDetails, setBankDetails] = useState(false);
    const [profileDetails, setProfileDetails] = useState(false);

    // Company Details Form Section Starts Here

    useEffect(() => {
        document.title = "Vendor Bank Details"
        window.scrollTo(0, 0)
        setMccList(categories)
        getData();
    }, [categories])

    useEffect(() => {
        const storedId = localStorage.getItem("sPuiId");
        if (storedId) {
            try {
                // const strId = JSON.parse(storedId);
                setCompanyDetails(false);
                setBankDetails(true);
                setProfileDetails(false);
                getStripeData(storedId);
            } catch (error) {
                console.error("Error parsing sPuiId from localStorage:", error);
            }
        }
    }, []);


    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedCountryCode, setSelectedCountryCode] = useState("");
    const [companyEmail, setCompanyEmail] = useState("");
    const [mccList, setMccList] = useState([]);
    const [mccName, setMccName] = useState("");
    const [mccCode, setMccCode] = useState("");
    const [selectedMcc, setSelectedMcc] = useState(null);
    const [businessUrl, setBusinessUrl] = useState("");
    const [businessDesc, setBusinessDesc] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [taxId, setTaxId] = useState("");
    const [phoneCode, setPhoneCode] = useState("44");
    const [stateDisabled, setStateDisabled] = useState(true);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedStateCode, setSelectedStateCode] = useState("");
    const [selectedCity, setSelectedCity] = useState("");
    const [line, setLine] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [btnDis, setButtonDis] = useState(false);
    const [states, setStates] = useState("");
    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(false);

    const getstates = (asi) => {
        setLoading(true);
        DataService.getStates(asi).then((data) => {
            setStates(data.data.data);
            if (data?.data?.data?.length > 0) {
                setStateDisabled(false);
            }
            else {
                setStateDisabled(true);
            }
        });
    }
    const getData = () => {
        setLoading(true);
        DataService.getCountries().then((data) => {
            setCountries(data.data.data);
        });
    }

    const handleSubmit = () => {
        setButtonDis(true);
        const ukPostalCodeRegex = /^(GIR 0AA|[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9][A-Z]{2})$/;
        const usPostalCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;
        if (selectedCountryCode === 'GB' && !ukPostalCodeRegex.test(postalCode)) {
            toast.error("Invalid UK postal code.");
            setButtonDis(false);
            return;
        }
        if (selectedCountryCode === 'US' && !usPostalCodeRegex.test(postalCode)) {
            toast.error("Invalid US ZIP code.");
            setButtonDis(false);
            return;
        }
        if (
            !selectedCountryCode ||
            !companyEmail ||
            !mccCode ||
            !mccName ||
            (!businessUrl && !businessDesc) ||
            !companyName ||
            !taxId ||
            !phoneCode ||
            !line ||
            !selectedCity ||
            !selectedStateCode ||
            !postalCode
        ) {
            toast.error("Please fill all required fields.");
            setButtonDis(false);
            return;
        }
        const data = {
            country: selectedCountryCode,
            email: companyEmail,
            business_type: "company",
            business_profile: {
                mcc: mccCode,
                mcc_name: mccName,
                url: businessUrl,
                product_description: businessDesc
            },
            company: {
                name: companyName,
                tax_id: taxId,
                executives_provided: false,
                phone: `+${phoneCode}`,
                address: {
                    line1: line,
                    city: selectedCity,
                    state: selectedStateCode,
                    postal_code: postalCode
                }
            }
        };

        DataService.createAccount(data).then(
            (res) => {
                toast.success("Company Details Submitted Successfully. Please Fill Bank Details Now!!");
                resetForm();
                setCompanyDetails(false)
                setBankDetails(true);
                setProfileDetails(false);
                localStorage.setItem("settpId", res?.data?.stripe?.id)
                localStorage.setItem("sPuiId", res?.data?.stripe?.id)
            },
            (error) => {
                const resMessage = error?.response?.data?.msg;
                toast.error(resMessage);
                setButtonDis(false);
            }
        );
    };
    const resetForm = () => {
        setSelectedCountryCode("");
        setSelectedCountry(null);
        setCompanyEmail("");
        setMccCode("");
        setSelectedMcc(null);
        setMccName("");
        setBusinessUrl("");
        setBusinessDesc("");
        setCompanyName("");
        setTaxId("");
        setPhoneCode("44");
        setLine("");
        setSelectedCity("");
        setSelectedState(null);
        setSelectedStateCode("");
        setPostalCode("");
        setButtonDis(false);
    };


    // Company Details Form Section Ends Here

    // Bank Details Form Section Starts Here

    const [selectedBankCountry, setSelectedBankCountry] = useState(null);
    const [selectedBankCountryCode, setSelectedBankCountryCode] = useState("");
    const [currencyCode, setCurrencyCode] = useState("GBP");
    const [accountNumber, setAccountNumber] = useState("");
    const [accountName, setAccountName] = useState("")
    const [cfAccountNumber, setCfAccountNumber] = useState("");
    const [routingNumber, setRoutingNumber] = useState("");

    const handleBankSubmit = () => {
        const stpId = localStorage.getItem("settpId")
        const storedId = localStorage.getItem("sPuiId");
        setButtonDis(true)
        if (
            !selectedBankCountryCode ||
            !currencyCode ||
            !accountNumber ||
            !cfAccountNumber ||
            !routingNumber
        ) {
            toast.error("Please fill all required fields.");
            setButtonDis(false);
            return;
        } else if (accountNumber !== cfAccountNumber) {
            toast.error("Account Number is not same.");
            setButtonDis(false);
            return;
        }
        const data = {
            account_holder_name: accountName,
            account_holder_type: "company",
            country: selectedBankCountryCode,
            currency: currencyCode,
            account_number: accountNumber,
            routing_number: routingNumber,
            account_id: stpId || storedId
        };
        Swal.fire({
            title: "Are you Sure?",
            text: "You won't be able to CHANGE BANK DETAILS again!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#02224E",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Submit Form!"
        }).then((result) => {
            if (result.isConfirmed) {
                DataService.addBankAccount(data).then(
                    () => {
                        toast.success("Bank Details Submitted Successfully. Please Fill Profile Details Now!!");
                        setSelectedBankCountry(null);
                        setSelectedBankCountryCode("");
                        setCurrencyCode("");
                        setAccountNumber("");
                        setCfAccountNumber("");
                        setRoutingNumber("");
                        setBankDetails(false);
                        setProfileDetails(true);
                        setCompanyDetails(false);
                        setButtonDis(false);
                    },
                    (error) => {
                        const resMessage = error?.response?.data?.msg;
                        toast.error(resMessage);
                        setLoading(false);
                        setButtonDis(false)
                    }
                );
            } else {
                setButtonDis(false);
            }
        });

    };

    // Bank Details Form Section Ends Here

    // Profile Details Form Section Starts Here

    const [profLine, setProfLine] = useState("");
    const [profPostalCode, setProfPostalCode] = useState("");
    const [profCity, setProfCity] = useState("");
    const [profSelectedCountry, setProfSelectedCountry] = useState(null);
    const [profSelectedCountryCode, setProfSelectedCountryCode] = useState("");
    const [profSelectedState, setProfSelectedState] = useState(null);
    const [profSelectedStateCode, setProfSelectedStateCode] = useState("");
    const [profStateDis, setProfStateDis] = useState(true);
    const [proStatesList, setProStatesList] = useState([]);
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [dob, setDob] = useState({ day: 1, month: 1, year: 1990 });
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [proEmail, setProEmail] = useState("");
    const [proPhoneCode, setProPhoneCode] = useState("44");
    const [role, setRole] = useState(null);
    const [gender, setGender] = useState(null);


    const maxDate = new Date(2010, 0, 1);
    const handleDateChange = (newValue) => {
        setDateOfBirth(newValue);

        if (newValue) {
            const selectedDate = new Date(newValue);
            const day = selectedDate.getDate();
            const month = selectedDate.getMonth() + 1;
            const year = selectedDate.getFullYear();
            setDob({ day, month, year });
        }
    };

    const genderOptions = [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
        { label: 'Other', value: 'other' }
    ];

    const roleOptions = [
        { label: 'Director', value: 'director' },
        { label: 'Executive', value: 'executive' },
        { label: 'Representative', value: 'representative' },
        { label: 'Owner', value: 'owner' }
    ];

    const getProfStates = (asi) => {
        setLoading(true);
        DataService.getStates(asi).then((data) => {
            setProStatesList(data.data.data);
            if (data?.data?.data?.length > 0) {
                setProfStateDis(false)
            }
            else {
                setProfStateDis(true)
            }
        });
    }

    const handleProfileSubmit = () => {
        const stpId = localStorage.getItem("settpId")
        const storedId = localStorage.getItem("sPuiId");
        setButtonDis(true);
        const ukPostalCodeRegex = /^(GIR 0AA|[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9][A-Z]{2})$/;
        const usPostalCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;

        if (profSelectedCountryCode === 'GB' && !ukPostalCodeRegex.test(profPostalCode)) {
            toast.error("Invalid UK postal code.");
            setButtonDis(false);
            return;
        }

        if (profSelectedCountryCode === 'US' && !usPostalCodeRegex.test(profPostalCode)) {
            toast.error("Invalid US ZIP code.");
            setButtonDis(false);
            return;
        }

        if (
            !firstName ||
            !lastName ||
            !profSelectedCountryCode ||
            !profSelectedStateCode ||
            !profCity ||
            !profLine ||
            !profPostalCode ||
            !proEmail ||
            !proPhoneCode ||
            !gender ||
            !role ||
            !dateOfBirth
        ) {
            toast.error("Please fill all required fields.");
            setButtonDis(false);
            return;
        }

        const relationship = {
            director: false,
            executive: false,
            owner: false,
            ...(roleOptions.reduce((acc, option) => {
                acc[option.value] = false;
                return acc;
            }, {})),
            title: role && role.length > 0 ? role.map(r => r.label).join(', ') : ''
        };

        if (role && role.length > 0) {
            role.forEach((r) => {
                relationship[r.value] = true;
            });
        }

        const data = {
            account_id: stpId || storedId,
            address: {
                line1: profLine,
                city: profCity,
                state: profSelectedStateCode,
                postal_code: profPostalCode,
                country: profSelectedCountryCode
            },
            dob: dob,
            gender: gender ? gender?.value : null,
            email: proEmail,
            first_name: firstName,
            last_name: lastName,
            phone: `+${proPhoneCode}`,
            relationship: relationship
        };
        DataService.addProfile(data).then(
            () => {
                toast.success("Profile Details Submitted Successfully!!");
                resetProForm();
                UpdateProfileSubmit();
                confirmStr();
                setBankDetails(false);
                setProfileDetails(false);
                setCompanyDetails(false);
                setButtonDis(false);
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            },
            (error) => {
                const resMessage = error?.response?.data?.msg;
                toast.error(resMessage);
                setButtonDis(false);
            }
        );
    };
    const UpdateProfileSubmit = () => {
        const stpId = localStorage.getItem("settpId")
        const storedId = localStorage.getItem("sPuiId");
        const data = {
            account_id: stpId || storedId,
            company: {
                directors_provided: true,
                executives_provided: true,
                owners_provided: true
            }
        };
        DataService.updateAccount(data).then(
            () => {

            },
            (error) => {
                const resMessage = error?.response?.data?.msg;
                toast.error(resMessage);
            }
        );
    };
    const confirmStr = () => {
        DataService.confirmStripe().then(
            () => {

            },
            (error) => {
                const resMessage = error?.response?.data?.msg;
                toast.error(resMessage);
            }
        );
    };
    useEffect(() => {
        confirmStr();
    },[])


    const resetProForm = () => {
        setFirstName("");
        setLastName("");
        setProEmail("");
        setProPhoneCode("44");
        setPostalCode("");
        setProfSelectedCountryCode("");
        setProfSelectedCountry(null);
        setProfSelectedState(null);
        setProfSelectedStateCode("");
        setProfCity("");
        setProfLine("");
        setGender(null);
        setDateOfBirth(null);
        setRole(null);
        setButtonDis(false);
    };

    // Profile Details Form Section Starts Here


    return (
        <>
            <div className="bg-grey h-100">
                <Header />
                <section className="content-area mt-4">
                    <Sidebar />
                    <div className="Right-content">
                        <div className="main-content">
                            <div className='bankTabs'>
                                <button
                                    disabled={allSubmissions}
                                    onClick={() => {
                                        setCompanyDetails(true)
                                        setBankDetails(false)
                                        setProfileDetails(false)
                                    }} className={`${companyDetails ? 'activated' : ''}`}>Company Details</button>
                                <button
                                    disabled={allSubmissions}
                                    onClick={() => {
                                        setCompanyDetails(false)
                                        setBankDetails(true)
                                        setProfileDetails(false)
                                    }}
                                    className={`${bankDetails ? 'activated' : ''}`}
                                >Bank Details</button>
                                <button
                                    disabled={allSubmissions}
                                    onClick={() => {
                                        setCompanyDetails(false)
                                        setBankDetails(false)
                                        setProfileDetails(true)
                                    }}
                                    className={`${profileDetails ? 'activated' : ''}`}
                                >Profile Details</button>
                            </div>
                            {companyDetails &&
                                <>
                                    <hr className='my-5' />
                                    {companyForm && <p className='text-danger'>*This Form is Already Submitted</p>}
                                    <div className='main_bankSec'>
                                        <h2>Please Add Company Details Here</h2>
                                        <span><span className='text-danger'>*</span>All Fields are Required</span>
                                        <div className='main_bankFormSec'>
                                            <div className='input_flexSec'>
                                                <div className='input_flexInner'>
                                                    <Autocomplete
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={"Select Your Country"}
                                                                placeholder={"Type to search"}
                                                            />
                                                        )}
                                                        options={countries}
                                                        id={"multiple-category-engg-selection"}
                                                        getOptionLabel={(option) =>
                                                            `${option?.name || ""
                                                            } ~ ${option?.iso2}`
                                                        }
                                                        disabled={companyForm}
                                                        onChange={(evt, newValue) => {
                                                            setSelectedCountry(newValue);
                                                            setSelectedCountryCode(newValue?.iso2)
                                                            setSelectedState(null)
                                                            getstates(newValue?.id)
                                                        }}
                                                        sx={{ width: "100%" }}
                                                        value={selectedCountry}
                                                    />
                                                </div>
                                                <div className='input_flexInner'>
                                                    <TextField
                                                        id="outlined-controlled"
                                                        label="Company Email"
                                                        value={companyEmail}
                                                        sx={{ width: "100%" }}
                                                        disabled={companyForm}
                                                        onChange={(event) => {
                                                            setCompanyEmail(event.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='form_innerSec'>
                                                <fieldset className="custom-fieldset">
                                                    <legend className="custom-legend">Business Details*</legend>
                                                    <div className='input_flexSec'>
                                                        <div className='input_flexInner'>
                                                            <Autocomplete
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label={"Select Your MCC"}
                                                                        placeholder={"Type to search"}
                                                                    />
                                                                )}
                                                                disabled={companyForm}
                                                                options={mccList}
                                                                id={"multiple-category-mcc-selection"}
                                                                getOptionLabel={(option) =>
                                                                    `${option?.category_name || ""}`
                                                                }
                                                                onChange={(evt, newValue) => {
                                                                    setSelectedMcc(newValue)
                                                                    setMccName(newValue?.category_name)
                                                                    setMccCode(newValue?.category_code)
                                                                }}
                                                                sx={{ width: "100%" }}
                                                                value={selectedMcc}
                                                            />
                                                        </div>
                                                        <div className='input_flexInner'>
                                                            <TextField
                                                                disabled={companyForm}
                                                                id="outlined-controlled"
                                                                label="Business URL"
                                                                value={businessUrl}
                                                                sx={{ width: "100%" }}
                                                                onChange={(event) => {
                                                                    setBusinessUrl(event.target.value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='input_flexSec mt-4'>
                                                        <TextField
                                                            id="outlined-controlled"
                                                            label="Business Description"
                                                            value={businessDesc}
                                                            sx={{ width: "100%" }}
                                                            onChange={(event) => {
                                                                setBusinessDesc(event.target.value);
                                                            }}
                                                            disabled={companyForm}

                                                            multiline
                                                            rows={4}
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </div>
                                                </fieldset>
                                            </div>
                                            <div className='form_innerSec'>
                                                <fieldset className="custom-fieldset">
                                                    <legend className="custom-legend">Company Details*</legend>
                                                    <div className='input_flexSec'>
                                                        <TextField
                                                            disabled={companyForm}
                                                            id="outlined-controlled"
                                                            label="Company Name"
                                                            value={companyName}
                                                            sx={{ width: "100%" }}
                                                            onChange={(event) => {
                                                                setCompanyName(event.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='input_flexSec mt-4'>
                                                        <div className='input_flexInner'>
                                                            <TextField
                                                                id="outlined-controlled"
                                                                label="TAX ID/CRN No."
                                                                value={taxId}
                                                                sx={{ width: "100%" }}
                                                                onChange={(event) => {
                                                                    setTaxId(event.target.value);
                                                                }}
                                                                disabled={companyForm}
                                                            />
                                                        </div>
                                                        <div className='input_flexInner'>
                                                            {/* <TextField                         
                                                        id="outlined-controlled"
                                                        label="Phone No."
                                                        value={phoneNumber}
                                                        sx={{ width: "100%" }}
                                                        onChange={(event) => {
                                                            setPhoneNumber(event.target.value);
                                                        }}
                                                    /> */}
                                                            <PhoneInput
                                                                disabled={companyForm}
                                                                country={"uk"}
                                                                value={phoneCode}
                                                                defaultCountry="UK"
                                                                onChange={setPhoneCode}
                                                            />
                                                        </div>
                                                    </div>
                                                    <fieldset className="custom-fieldset">
                                                        <legend className="custom-legend">Address*</legend>
                                                        <div className='input_flexSec mb-4'>
                                                            <div className='input_flexInner'>
                                                                <TextField
                                                                    id="outlined-controlled"
                                                                    label="Address Line"
                                                                    value={line}
                                                                    disabled={companyForm}
                                                                    sx={{ width: "100%" }}
                                                                    onChange={(event) => {
                                                                        setLine(event.target.value);
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className='input_flexInner'>
                                                                <Autocomplete
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            label={"Select Your State"}
                                                                            placeholder={"Type to search"}
                                                                        />
                                                                    )}
                                                                    disabled={stateDisabled || companyForm}
                                                                    options={states}
                                                                    id={"multiple-category-state-selection"}
                                                                    getOptionLabel={(option) =>
                                                                        `${option?.name || ""
                                                                        } ~ ${option?.iso2}`
                                                                    }
                                                                    onChange={(evt, newValue) => {
                                                                        setSelectedState(newValue);
                                                                        setSelectedStateCode(newValue?.iso2);
                                                                    }}
                                                                    sx={{ width: "100%" }}
                                                                    value={selectedState}
                                                                />

                                                            </div>
                                                        </div>
                                                        <div className='input_flexSec'>
                                                            <div className='input_flexInner'>
                                                                <TextField
                                                                    id="outlined-controlled"
                                                                    label="City"
                                                                    value={selectedCity}
                                                                    sx={{ width: "100%" }}
                                                                    disabled={companyForm}
                                                                    onChange={(event) => {
                                                                        setSelectedCity(event.target.value);
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className='input_flexInner'>
                                                                <TextField
                                                                    id="outlined-controlled"
                                                                    label="Postal Code"
                                                                    value={postalCode}
                                                                    disabled={companyForm}
                                                                    sx={{ width: "100%" }}
                                                                    onChange={(event) => {
                                                                        setPostalCode(event.target.value);
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </fieldset>
                                            </div>
                                            <div className='submit_sec text-center'>
                                                <button className='btn btn-primary px-5' disabled={btnDis || companyForm} onClick={handleSubmit}>Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            {bankDetails &&
                                <>
                                    <hr className='my-5' />
                                    {bankForm && <p className='text-danger'>*This Form is Already Submitted</p>}
                                    <div className='main_bankSec'>
                                        <h2>Please Add Bank Details Here</h2>
                                        <span><span className='text-danger'>*</span>All Fields are Required</span>
                                        <div className='main_bankFormSec'>
                                            <div className='input_flexSec'>
                                                <div className='input_flexInner'>
                                                    <Autocomplete
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={"Select Your Bank Country"}
                                                                placeholder={"Type to search"}
                                                            />
                                                        )}
                                                        disabled={bankForm}
                                                        options={countries}
                                                        id={"multiple-category-bank-selection"}
                                                        getOptionLabel={(option) =>
                                                            `${option?.name || ""
                                                            } ~ ${option?.iso2}`
                                                        }
                                                        onChange={(evt, newValue) => {
                                                            setSelectedBankCountry(newValue);
                                                            setSelectedBankCountryCode(newValue?.iso2)
                                                        }}
                                                        sx={{ width: "100%" }}
                                                        value={selectedBankCountry}
                                                    />
                                                </div>
                                                <div className='input_flexInner'>
                                                    <TextField
                                                        id="outlined-controlled"
                                                        label="Currency"
                                                        disabled={true || bankForm}
                                                        value={currencyCode}
                                                        sx={{ width: "100%" }}
                                                        onChange={(event) => {
                                                            setCurrencyCode(event.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='form_innerSec'>
                                                <fieldset className="custom-fieldset">
                                                    <legend className="custom-legend">Account Details*</legend>
                                                    <div className='input_flexSec mb-4'>
                                                        <TextField
                                                            id="outlined-controlled"
                                                            label="Enter Account Name"
                                                            disabled={bankForm}
                                                            value={accountName}
                                                            sx={{ width: "100%" }}
                                                            onChange={(event) => {
                                                                setAccountName(event.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='input_flexSec'>
                                                        <div className='input_flexInner'>
                                                            <TextField
                                                                id="outlined-controlled"
                                                                label="Enter Account Number"
                                                                value={accountNumber}
                                                                disabled={bankForm}
                                                                sx={{ width: "100%" }}
                                                                onChange={(event) => {
                                                                    setAccountNumber(event.target.value);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='input_flexInner'>
                                                            <TextField
                                                                id="outlined-controlled"
                                                                label="Confirm Account Number"
                                                                value={cfAccountNumber}
                                                                disabled={bankForm}

                                                                sx={{ width: "100%" }}
                                                                onChange={(event) => {
                                                                    setCfAccountNumber(event.target.value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='input_flexSec mt-4'>
                                                        <TextField
                                                            id="outlined-controlled"
                                                            label="Sort code / Routing Number"
                                                            value={routingNumber}
                                                            disabled={bankForm}

                                                            sx={{ width: "100%" }}
                                                            onChange={(event) => {
                                                                setRoutingNumber(event.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </fieldset>
                                            </div>
                                            <div className='submit_sec text-center'>
                                                <button className='btn btn-primary px-5' disabled={btnDis || bankForm} onClick={handleBankSubmit}>Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            {profileDetails &&
                                <>
                                    <hr className='my-5' />
                                    {profileForm && <p className='text-danger'>*This Form is Already Submitted</p>}
                                    <div className='main_bankSec'>
                                        <h2>Please Add Profile Details Here</h2>
                                        <span><span className='text-danger'>*</span>All Fields are Required</span>
                                        <div className='main_bankFormSec'>
                                            <div className='form_innerSec'>
                                                <fieldset className="custom-fieldset">
                                                    <legend className="custom-legend">Address*</legend>
                                                    <div className='input_flexSec mb-4'>
                                                        <div className='input_flexInner'>
                                                            <Autocomplete
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label={"Select Your Country"}
                                                                        placeholder={"Type to search"}
                                                                    />
                                                                )}
                                                                disabled={profileForm}
                                                                options={countries}
                                                                id={"multiple-category-bank-selection"}
                                                                getOptionLabel={(option) =>
                                                                    `${option?.name || ""
                                                                    } ~ ${option?.iso2}`
                                                                }
                                                                onChange={(evt, newValue) => {
                                                                    setProfSelectedCountry(newValue)
                                                                    setProfSelectedCountryCode(newValue?.iso2)
                                                                    getProfStates(newValue?.id)
                                                                }}
                                                                sx={{ width: "100%" }}
                                                                value={profSelectedCountry}
                                                            />
                                                        </div>
                                                        <div className='input_flexInner'>
                                                            <Autocomplete
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label={"Select Your State"}
                                                                        placeholder={"Type to search"}
                                                                    />
                                                                )}
                                                                disabled={profStateDis || profileForm}
                                                                options={proStatesList}
                                                                id={"multiple-category-state-selection"}
                                                                getOptionLabel={(option) =>
                                                                    `${option?.name || ""
                                                                    } ~ ${option?.iso2}`
                                                                }
                                                                onChange={(evt, newValue) => {
                                                                    setProfSelectedState(newValue);
                                                                    setProfSelectedStateCode(newValue?.iso2);
                                                                }}
                                                                sx={{ width: "100%" }}
                                                                value={profSelectedState}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='input_flexSec mb-4'>
                                                        <div className='input_flexInner'>
                                                            <TextField
                                                                id="outlined-controlled"
                                                                label="City"
                                                                value={profCity}
                                                                disabled={profileForm}
                                                                sx={{ width: "100%" }}
                                                                onChange={(event) => {
                                                                    setProfCity(event.target.value);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='input_flexInner'>
                                                            <TextField
                                                                id="outlined-controlled"
                                                                label="Postal Code"
                                                                value={profPostalCode}
                                                                disabled={profileForm}
                                                                sx={{ width: "100%" }}
                                                                onChange={(event) => {
                                                                    setProfPostalCode(event.target.value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='input_flexSec'>
                                                        <TextField
                                                            id="outlined-controlled"
                                                            label="Address Line"
                                                            value={profLine}
                                                            disabled={profileForm}
                                                            sx={{ width: "100%" }}
                                                            onChange={(event) => {
                                                                setProfLine(event.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </fieldset>
                                                <div className='input_flexSec mt-4'>
                                                    <div className='input_flexInner'>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DesktopDatePicker
                                                                label="Date of Birth"
                                                                inputFormat="MM/dd/yyyy"
                                                                value={dateOfBirth}
                                                                disabled={profileForm}
                                                                onChange={handleDateChange}
                                                                maxDate={maxDate}
                                                                sx={{ width: "100%" }}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </LocalizationProvider>
                                                    </div>
                                                    <div className='input_flexInner'>
                                                        <Autocomplete
                                                            options={genderOptions}
                                                            getOptionLabel={(option) => option.label}
                                                            value={gender}
                                                            disabled={profileForm}
                                                            onChange={(evt, newValue) => {
                                                                setGender(newValue);
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField {...params} label="Select Gender" variant="outlined" />
                                                            )}
                                                            disableClearable
                                                            isOptionEqualToValue={(option, value) => option.value === value?.value}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='input_flexSec mt-4'>
                                                    <div className='input_flexInner'>
                                                        <TextField
                                                            id="outlined-controlled"
                                                            label="First Name"
                                                            value={firstName}
                                                            disabled={profileForm}
                                                            sx={{ width: "100%" }}
                                                            onChange={(event) => {
                                                                setFirstName(event.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='input_flexInner'>
                                                        <TextField
                                                            id="outlined-controlled"
                                                            label="Last Name"
                                                            value={lastName}
                                                            disabled={profileForm}
                                                            sx={{ width: "100%" }}
                                                            onChange={(event) => {
                                                                setLastName(event.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='input_flexSec mt-4'>
                                                    <div className='input_flexInner'>
                                                        <TextField
                                                            id="outlined-controlled"
                                                            label="Email"
                                                            value={proEmail}
                                                            disabled={profileForm}
                                                            sx={{ width: "100%" }}
                                                            onChange={(event) => {
                                                                setProEmail(event.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='input_flexInner'>
                                                        <PhoneInput
                                                            country={"uk"}
                                                            disabled={profileForm}
                                                            value={proPhoneCode}
                                                            defaultCountry="UK"
                                                            onChange={setProPhoneCode}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='input_flexSec mt-4'>
                                                    <Autocomplete
                                                        multiple
                                                        options={roleOptions || []}
                                                        getOptionLabel={(option) => option?.label || ''}
                                                        value={role || []}
                                                        onChange={(evt, newValue) => {
                                                            setRole(newValue);
                                                        }}
                                                        disabled={profileForm}
                                                        sx={{ width: '100%' }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Select Role" variant="outlined" />
                                                        )}
                                                        disableClearable
                                                    />
                                                </div>
                                            </div>
                                            <div className='submit_sec text-center'>
                                                <button className='btn btn-primary px-5' disabled={btnDis || profileForm} onClick={handleProfileSubmit}>Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        <Footer />
                    </div>
                </section>
            </div>
        </>
    )
}

export default CompanyAccount