import React, { Fragment } from "react";

const SignUpFieldContent = () => {
    return (
        <div className="container-fluid">

            <form>


                <div className="row">
                    <div className="col-md-12">

                        <div className="card card-highlight mb-5">
                            <div className="card-body p-4">
                                <h5 className="mb-4 f-700">User Sign up</h5>
                                <div className="row">
                                    <div className="col-md-12 py-3 border-bottom d-flex justify-content-between align-items-center">
                                        <span className="f-24">First Name</span>
                                        <label class="switch"><input type="checkbox" /><span class="slider round"></span></label>
                                    </div>
                                    <div className="col-md-12 py-3 border-bottom d-flex justify-content-between align-items-center">
                                        <span className="f-24">Last Name</span>
                                        <label class="switch"><input type="checkbox" /><span class="slider round"></span></label>
                                    </div>
                                    <div className="col-md-12 py-3 border-bottom d-flex justify-content-between align-items-center">
                                        <span className="f-24">Mobile Number</span>
                                        <label class="switch"><input type="checkbox" /><span class="slider round"></span></label>
                                    </div>
                                    <div className="col-md-12 py-3 border-bottom d-flex justify-content-between align-items-center">
                                        <span className="f-24">Email</span>
                                        <label class="switch"><input type="checkbox" /><span class="slider round"></span></label>
                                    </div>
                                    <div className="col-md-12 py-3 d-flex justify-content-between align-items-center">
                                        <span className="f-24">Password</span>
                                        <label class="switch"><input type="checkbox" /><span class="slider round"></span></label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card card-highlight mb-5">
                            <div className="card-body p-4">
                                <h5 className="mb-4 f-700">Vendor  Sign up</h5>
                                <div className="row">
                                    <div className="col-md-12 py-3 border-bottom d-flex justify-content-between align-items-center">
                                        <span className="f-24">First Name</span>
                                        <label class="switch"><input type="checkbox" /><span class="slider round"></span></label>
                                    </div>
                                    <div className="col-md-12 py-3 border-bottom d-flex justify-content-between align-items-center">
                                        <span className="f-24">Middle Name</span>
                                        <label class="switch"><input type="checkbox" /><span class="slider round"></span></label>
                                    </div>
                                  
                                    <div className="col-md-12 py-3 border-bottom d-flex justify-content-between align-items-center">
                                        <span className="f-24">Last Name</span>
                                        <label class="switch"><input type="checkbox" /><span class="slider round"></span></label>
                                    </div>
                                    <div className="col-md-12 py-3 border-bottom d-flex justify-content-between align-items-center">
                                        <span className="f-24">Company/Business Name*</span>
                                        <label class="switch"><input type="checkbox" /><span class="slider round"></span></label>
                                    </div>
                                    <div className="col-md-12 py-3 border-bottom d-flex justify-content-between align-items-center">
                                        <span className="f-24">Website Link</span>
                                        <label class="switch"><input type="checkbox" /><span class="slider round"></span></label>
                                    </div>
                                    <div className="col-md-12 py-3 border-bottom d-flex justify-content-between align-items-center">
                                        <span className="f-24">Email</span>
                                        <label class="switch"><input type="checkbox" /><span class="slider round"></span></label>
                                    </div>
                                    <div className="col-md-12 py-3 border-bottom d-flex justify-content-between align-items-center">
                                        <span className="f-24">Phone Number</span>
                                        <label class="switch"><input type="checkbox" /><span class="slider round"></span></label>
                                    </div>
                                    <div className="col-md-12 py-3 border-bottom d-flex justify-content-between align-items-center">
                                        <span className="f-24">Address</span>
                                        <label class="switch"><input type="checkbox" /><span class="slider round"></span></label>
                                    </div>
                                    <div className="col-md-12 py-3 border-bottom d-flex justify-content-between align-items-center">
                                        <span className="f-24">State</span>
                                        <label class="switch"><input type="checkbox" /><span class="slider round"></span></label>
                                    </div>
                                    <div className="col-md-12 py-3 border-bottom d-flex justify-content-between align-items-center">
                                        <span className="f-24">City</span>
                                        <label class="switch"><input type="checkbox" /><span class="slider round"></span></label>
                                    </div>
                                    <div className="col-md-12 py-3 border-bottom d-flex justify-content-between align-items-center">
                                        <span className="f-24">Name of Referral</span>
                                        <label class="switch"><input type="checkbox" /><span class="slider round"></span></label>
                                    </div>
                                    <div className="col-md-12 py-3 d-flex justify-content-between align-items-center">
                                        <span className="f-24">Document upload</span>
                                        <label class="switch"><input type="checkbox" /><span class="slider round"></span></label>
                                    </div>

                                </div>
                            </div>
                        </div>


                       
                    </div>
                </div>
            </form>
        </div>
    );
};

export default SignUpFieldContent;