const categories = [
    { category_name: "A/C, Refrigeration Repair", category_value: "ac_refrigeration_repair", category_code: "7623" },
    { category_name: "Accounting/Bookkeeping Services", category_value: "accounting_bookkeeping_services", category_code: "8931" },
    { category_name: "Advertising Services", category_value: "advertising_services", category_code: "7311" },
    { category_name: "Agricultural Cooperative", category_value: "agricultural_cooperative", category_code: "0763" },
    { category_name: "Airlines, Air Carriers", category_value: "airlines_air_carriers", category_code: "4511" },
    { category_name: "Airports, Flying Fields", category_value: "airports_flying_fields", category_code: "4582" },
    { category_name: "Ambulance Services", category_value: "ambulance_services", category_code: "4119" },
    { category_name: "Amusement Parks/Carnivals", category_value: "amusement_parks_carnivals", category_code: "7996" },
    { category_name: "Antique Reproductions", category_value: "antique_reproductions", category_code: "5937" },
    { category_name: "Antique Shops", category_value: "antique_shops", category_code: "5932" },
    { category_name: "Aquariums", category_value: "aquariums", category_code: "7998" },
    { category_name: "Architectural/Surveying Services", category_value: "architectural_surveying_services", category_code: "8911" },
    { category_name: "Art Dealers and Galleries", category_value: "art_dealers_and_galleries", category_code: "5971" },
    { category_name: "Artists Supply and Craft Shops", category_value: "artists_supply_and_craft_shops", category_code: "5970" },
    { category_name: "Auto Body Repair Shops", category_value: "auto_body_repair_shops", category_code: "7531" },
    { category_name: "Auto Paint Shops", category_value: "auto_paint_shops", category_code: "7535" },
    { category_name: "Auto Service Shops", category_value: "auto_service_shops", category_code: "7538" },
    { category_name: "Auto and Home Supply Stores", category_value: "auto_and_home_supply_stores", category_code: "5531" },
    { category_name: "Automated Fuel Dispensers", category_value: "automated_fuel_dispensers", category_code: "5542" },
    { category_name: "Automobile Associations", category_value: "automobile_associations", category_code: "8675" },
    { category_name: "Automotive Parts and Accessories Stores", category_value: "automotive_parts_and_accessories_stores", category_code: "5533" },
    { category_name: "Automotive Tire Stores", category_value: "automotive_tire_stores", category_code: "5532" },
    { category_name: "Bail and Bond Payments", category_value: "bail_and_bond_payments", category_code: "9223" },
    { category_name: "Bakeries", category_value: "bakeries", category_code: "5462" },
    { category_name: "Bands, Orchestras", category_value: "bands_orchestras", category_code: "7929" },
    { category_name: "Barber and Beauty Shops", category_value: "barber_and_beauty_shops", category_code: "7230" },
    { category_name: "Betting/Casino Gambling", category_value: "betting_casino_gambling", category_code: "7995" },
    { category_name: "Bicycle Shops", category_value: "bicycle_shops", category_code: "5940" },
    { category_name: "Billiard/Pool Establishments", category_value: "billiard_pool_establishments", category_code: "7932" },
    { category_name: "Boat Dealers", category_value: "boat_dealers", category_code: "5551" },
    { category_name: "Boat Rentals and Leases", category_value: "boat_rentals_and_leases", category_code: "4457" },
    { category_name: "Book Stores", category_value: "book_stores", category_code: "5942" },
    { category_name: "Books, Periodicals, and Newspapers", category_value: "books_periodicals_and_newspapers", category_code: "5192" },
    { category_name: "Bowling Alleys", category_value: "bowling_alleys", category_code: "7933" },
    { category_name: "Bus Lines", category_value: "bus_lines", category_code: "4131" },
    { category_name: "Business/Secretarial Schools", category_value: "business_secretarial_schools", category_code: "8244" },
    { category_name: "Buying/Shopping Services", category_value: "buying_shopping_services", category_code: "7278" },
    { category_name: "Cable, Satellite, and Other Pay Television and Radio", category_value: "cable_satellite_and_other_pay_television_and_radio", category_code: "4899" },
    { category_name: "Camera and Photographic Supply Stores", category_value: "camera_and_photographic_supply_stores", category_code: "5946" },
    { category_name: "Candy, Nut, and Confectionery Stores", category_value: "candy_nut_and_confectionery_stores", category_code: "5441" },
    { category_name: "Car Rental Agencies", category_value: "car_rental_agencies", category_code: "7512" },
    { category_name: "Car Washes", category_value: "car_washes", category_code: "7542" },
    { category_name: "Car and Truck Dealers (New & Used)", category_value: "car_and_truck_dealers_new_used", category_code: "5511" },
    { category_name: "Car and Truck Dealers (Used Only)", category_value: "car_and_truck_dealers_used_only", category_code: "5521" },
    { category_name: "Carpentry Services", category_value: "carpentry_services", category_code: "1750" },
    { category_name: "Carpet/Upholstery Cleaning", category_value: "carpet_upholstery_cleaning", category_code: "7217" },
    { category_name: "Caterers", category_value: "caterers", category_code: "5811" },
    { category_name: "Charitable and Social Service Organizations - Fundraising", category_value: "charitable_and_social_service_organizations_fundraising", category_code: "8398" },
    { category_name: "Chemicals and Allied Products (Not Elsewhere Classified)", category_value: "chemicals_and_allied_products", category_code: "5169" },
    { category_name: "Child Care Services", category_value: "child_care_services", category_code: "8351" },
    { category_name: "Childrens and Infants Wear Stores", category_value: "childrens_and_infants_wear_stores", category_code: "5641" },
    { category_name: "Chiropodists, Podiatrists", category_value: "chiropodists_podiatrists", category_code: "8049" },
    { category_name: "Chiropractors", category_value: "chiropractors", category_code: "8041" },
    { category_name: "Cigar Stores and Stands", category_value: "cigar_stores_and_stands", category_code: "5993" },
    { category_name: "Civic, Social, Fraternal Associations", category_value: "civic_social_fraternal_associations", category_code: "8641" },
    { category_name: "Cleaning and Maintenance", category_value: "cleaning_and_maintenance", category_code: "7349" },
    { category_name: "Clothing Rental", category_value: "clothing_rental", category_code: "7296" },
    { category_name: "Colleges, Universities", category_value: "colleges_universities", category_code: "8220" },
    { category_name: "Commercial Equipment (Not Elsewhere Classified)", category_value: "commercial_equipment", category_code: "5046" },
    { category_name: "Commercial Footwear", category_value: "commercial_footwear", category_code: "5139" },
    { category_name: "Commercial Photography, Art and Graphics", category_value: "commercial_photography_art_and_graphics", category_code: "7333" },
    { category_name: "Commuter Transport, Ferries", category_value: "commuter_transport_and_ferries", category_code: "4111" },
    { category_name: "Computer Network Services", category_value: "computer_network_services", category_code: "4816" },
    { category_name: "Computer Programming", category_value: "computer_programming", category_code: "7372" },
    { category_name: "Computer Repair", category_value: "computer_repair", category_code: "7379" },
    { category_name: "Computer Software Stores", category_value: "computer_software_stores", category_code: "5734" },
    { category_name: "Concrete Work", category_value: "concrete_work", category_code: "1771" },
    { category_name: "Construction Services", category_value: "construction_services", category_code: "1542" },
    { category_name: "Consulting Services", category_value: "consulting_services", category_code: "8742" },
    { category_name: "Convenience Stores", category_value: "convenience_stores", category_code: "5411" },
    { category_name: "Copying and Duplicating Services", category_value: "copying_and_duplicate_services", category_code: "7338" },
    { category_name: "Cosmetics Stores", category_value: "cosmetics_stores", category_code: "5972" },
    { category_name: "Counseling Services", category_value: "counseling_services", category_code: "8322" },
    { category_name: "Couriers", category_value: "couriers", category_code: "4214" },
    { category_name: "Craft Shops", category_value: "craft_shops", category_code: "5945" },
    { category_name: "Credit Card Services", category_value: "credit_card_services", category_code: "6012" },
    { category_name: "Dairy Products Stores", category_value: "dairy_products_stores", category_code: "5451" },
    { category_name: "Dance Halls, Studios, and Schools", category_value: "dance_halls_studios_and_schools", category_code: "7941" },
    { category_name: "Data Processing Services", category_value: "data_processing_services", category_code: "7374" },
    { category_name: "Delicatessens", category_value: "delicatessens", category_code: "5812" },
    { category_name: "Dentists", category_value: "dentists", category_code: "8021" },
    { category_name: "Department Stores", category_value: "department_stores", category_code: "5311" },
    { category_name: "Digital Goods - Games", category_value: "digital_goods_games", category_code: "5816" },
    { category_name: "Digital Goods - Software", category_value: "digital_goods_software", category_code: "5815" },
    { category_name: "Digital Goods - Video", category_value: "digital_goods_video", category_code: "5814" },
    { category_name: "Direct Marketing - Catalog Merchant", category_value: "direct_marketing_catalog_merchant", category_code: "5964" },
    { category_name: "Direct Marketing - Continuity/Subscription", category_value: "direct_marketing_continuity_subscription", category_code: "5963" },
    { category_name: "Direct Marketing - Insurance", category_value: "direct_marketing_insurance", category_code: "5967" },
    { category_name: "Direct Marketing - Other", category_value: "direct_marketing_other", category_code: "5969" },
    { category_name: "Direct Marketing - Travel", category_value: "direct_marketing_travel", category_code: "5965" },
    { category_name: "Disaster Relief Services", category_value: "disaster_relief_services", category_code: "8323" },
    { category_name: "Draperies, Curtains, and Upholstery Stores", category_value: "draperies_curtains_and_upholstery_stores", category_code: "5714" },
    { category_name: "Drug Stores", category_value: "drug_stores", category_code: "5912" },
    { category_name: "Dry Cleaning Services", category_value: "dry_cleaning_services", category_code: "7216" },
    { category_name: "Educational Services", category_value: "educational_services", category_code: "8299" },
    { category_name: "Electricians", category_value: "electricians", category_code: "1731" },
    { category_name: "Electronics Stores", category_value: "electronics_stores", category_code: "5732" },
    { category_name: "Emergency Services", category_value: "emergency_services", category_code: "9111" },
    { category_name: "Employment Services", category_value: "employment_services", category_code: "7361" },
    { category_name: "Engine Repair", category_value: "engine_repair", category_code: "7538" },
    { category_name: "Engineers", category_value: "engineers", category_code: "8711" },
    { category_name: "Entertainment Services", category_value: "entertainment_services", category_code: "7999" },
    { category_name: "Environmental Services", category_value: "environmental_services", category_code: "8744" },
    { category_name: "Equestrian Services", category_value: "equestrian_services", category_code: "7999" },
    { category_name: "Event Planning Services", category_value: "event_planning_services", category_code: "7299" },
    { category_name: "Exercise Facilities", category_value: "exercise_facilities", category_code: "7991" },
    { category_name: "Family Clothing Stores", category_value: "family_clothing_stores", category_code: "5651" },
    { category_name: "Fast Food Restaurants", category_value: "fast_food_restaurants", category_code: "5814" },
    { category_name: "Financial Institutions", category_value: "financial_institutions", category_code: "6011" },
    { category_name: "Financial Services", category_value: "financial_services", category_code: "6282" },
    { category_name: "Florists", category_value: "florists", category_code: "5992" },
    { category_name: "Fuel Dealers", category_value: "fuel_dealers", category_code: "5983" },
    { category_name: "Funeral Services", category_value: "funeral_services", category_code: "7261" },
    { category_name: "Furniture Stores", category_value: "furniture_stores", category_code: "5712" },
    { category_name: "Gas Stations", category_value: "gas_stations", category_code: "5541" },
    { category_name: "Gift, Novelty, and Souvenir Shops", category_value: "gift_novelty_and_souvenir_shops", category_code: "5947" },
    { category_name: "Glass Stores", category_value: "glass_stores", category_code: "5995" },
    { category_name: "Golf Courses and Country Clubs", category_value: "golf_courses_and_country_clubs", category_code: "7992" },
    { category_name: "Graphic Design Services", category_value: "graphic_design_services", category_code: "7330" },
    { category_name: "Grocery Stores", category_value: "grocery_stores", category_code: "5411" },
    { category_name: "Hardware Stores", category_value: "hardware_stores", category_code: "5251" },
    { category_name: "Health and Beauty Shops", category_value: "health_and_beauty_shops", category_code: "5999" },
    { category_name: "Health Clubs", category_value: "health_clubs", category_code: "7991" },
    { category_name: "Heating and Air Conditioning", category_value: "heating_and_air_conditioning", category_code: "7623" },
    { category_name: "Home Improvement Stores", category_value: "home_improvement_stores", category_code: "5211" },
    { category_name: "Home Security Systems", category_value: "home_security_systems", category_code: "7382" },
    { category_name: "Hospitals", category_value: "hospitals", category_code: "8062" },
    { category_name: "Hotels and Motels", category_value: "hotels_and_motels", category_code: "7011" },
    { category_name: "Household Appliances", category_value: "household_appliances", category_code: "5722" },
    { category_name: "Household Furniture", category_value: "household_furniture", category_code: "5712" },
    { category_name: "Industrial Machinery and Equipment", category_value: "industrial_machinery_and_equipment", category_code: "5084" },
    { category_name: "Insurance Agents/Brokers", category_value: "insurance_agents_brokers", category_code: "6411" },
    { category_name: "Insurance Companies", category_value: "insurance_companies", category_code: "6300" },
    { category_name: "Internet Service Providers", category_value: "internet_service_providers", category_code: "4814" },
    { category_name: "Investments", category_value: "investments", category_code: "6282" },
    { category_name: "Jewelry Stores", category_value: "jewelry_stores", category_code: "5944" },
    { category_name: "Landscaping Services", category_value: "landscaping_services", category_code: "0780" },
    { category_name: "Lawn and Garden Supply Stores", category_value: "lawn_and_garden_supply_stores", category_code: "5261" },
    { category_name: "Libraries", category_value: "libraries", category_code: "8231" },
    { category_name: "Limousines and Taxis", category_value: "limousines_and_taxis", category_code: "4121" },
    { category_name: "Liquor Stores", category_value: "liquor_stores", category_code: "5921" },
    { category_name: "Lodging", category_value: "lodging", category_code: "7011" },
    { category_name: "Mail Order Houses", category_value: "mail_order_houses", category_code: "5961" },
    { category_name: "Manufacturers", category_value: "manufacturers", category_code: "5084" },
    { category_name: "Medical Services", category_value: "medical_services", category_code: "8011" },
    { category_name: "Membership Organizations", category_value: "membership_organizations", category_code: "8641" },
    { category_name: "Miscellaneous Business Services", category_value: "miscellaneous_business_services", category_code: "7399" },
    { category_name: "Miscellaneous Repair Shops", category_value: "miscellaneous_repair_shops", category_code: "7699" },
    { category_name: "Mobile Home Dealers", category_value: "mobile_home_dealers", category_code: "5271" },
    { category_name: "Mortgage Bankers and Loan Correspondents", category_value: "mortgage_bankers_and_loan_correspondents", category_code: "6163" },
    { category_name: "Moving/Storage", category_value: "moving_storage", category_code: "4214" },
    { category_name: "Musical Instruments", category_value: "musical_instruments", category_code: "5736" },
    { category_name: "Nail Salons", category_value: "nail_saloons", category_code: "7231" },
    { category_name: "Non-Profit Organizations", category_value: "non_profit_organizations", category_code: "8399" },
    { category_name: "Notaries", category_value: "notaries", category_code: "8999" },
    { category_name: "Nurseries and Garden Centers", category_value: "nurseries_and_garden_centers", category_code: "5261" },
    { category_name: "Nursing and Personal Care", category_value: "nursing_and_personal_care", category_code: "8059" },
    { category_name: "Online Services", category_value: "online_services", category_code: "7299" },
    { category_name: "Optometrists", category_value: "optometrists", category_code: "8042" },
    { category_name: "Orthodontists", category_value: "orthodontists", category_code: "8021" },
    { category_name: "Other Services (Not Elsewhere Classified)", category_value: "other_services", category_code: "8999" },
    { category_name: "Outdoor Power Equipment Stores", category_value: "outdoor_power_equipment_stores", category_code: "5251" },
    { category_name: "Paint Stores", category_value: "paint_stores", category_code: "5999" },
    { category_name: "Parks", category_value: "parks", category_code: "7999" },
    { category_name: "Payroll Services", category_value: "payroll_services", category_code: "7299" },
    { category_name: "Pet Stores", category_value: "pet_stores", category_code: "5995" },
    { category_name: "Photography", category_value: "photography", category_code: "7399" },
    { category_name: "Physical Therapy", category_value: "physical_therapy", category_code: "8041" },
    { category_name: "Pizza Restaurants", category_value: "pizza_restaurants", category_code: "5814" },
    { category_name: "Plumbing Services", category_value: "plumbing_services", category_value: "1711" },
    { category_name: "Professional Services", category_value: "professional_services", category_code: "8742" },
    { category_name: "Public Utilities", category_value: "public_utilities", category_code: "4900" },
    { category_name: "Real Estate Agents and Managers", category_value: "real_estate_agents_and_managers", category_code: "6531" },
    { category_name: "Real Estate Investment Trusts (REITs)", category_value: "real_estate_investment_trusts_reits", category_code: "6798" },
    { category_name: "Recreational Vehicle Dealers", category_value: "recreational_vehicle_dealers", category_code: "5512" },
    { category_name: "Recycling Centers", category_value: "recycling_centers", category_code: "4953" },
    { category_name: "Rental and Leasing Services", category_value: "rental_and_leasing_services", category_code: "7359" },
    { category_name: "Repair Services", category_value: "repair_services", category_code: "7699" },
    { category_name: "Residential Construction", category_value: "residential_construction", category_code: "1521" },
    { category_name: "Restaurants", category_value: "restaurants", category_code: "5812" },
    { category_name: "Retail", category_value: "retail", category_code: "5999" },
    { category_name: "Roofing Services", category_value: "roofing_services", category_code: "1761" },
    { category_name: "Security Systems Services", category_value: "security_systems_services", category_code: "7382" },
    { category_name: "Sewing, Needlework, and Piece Goods Stores", category_value: "sewing_needlework_and_piece_goods_stores", category_code: "5949" },
    { category_name: "Shopping Services", category_value: "shopping_services", category_code: "7299" },
    { category_name: "Shoe Stores", category_value: "shoe_stores", category_code: "5651" },
    { category_name: "Sporting Goods Stores", category_value: "sporting_goods_stores", category_code: "5941" },
    { category_name: "Stationery Stores", category_value: "stationery_stores", category_code: "5943" },
    { category_name: "Steel Fabrication", category_value: "steel_fabrication", category_code: "3499" },
    { category_name: "Tanning Salons", category_value: "tanning_saloons", category_code: "7230" },
    { category_name: "Taxi Services", category_value: "taxi_services", category_code: "4121" },
    { category_name: "Tire Retreaders", category_value: "tire_retreaders", category_code: "7534" },
    { category_name: "Towing Services", category_value: "towing_services", category_code: "7542" },
    { category_name: "Travel Agencies", category_value: "travel_agencies", category_code: "4721" },
    { category_name: "Travel Services", category_value: "travel_services", category_code: "4722" },
    { category_name: "Tree Services", category_value: "tree_services", category_code: "0782" },
    { category_name: "Used Car Dealers", category_value: "used_car_dealers", category_code: "5521" },
    { category_name: "Veterinarians", category_value: "veterinarians", category_code: "0742" },
    { category_name: "Watch/Jewelry Repair", category_value: "watch_jewelry_repair", category_code: "7631" },
    { category_name: "Welding Repair", category_value: "welding_repair", category_code: "7692" },
    { category_name: "Wholesale Clubs", category_value: "wholesale_clubs", category_code: "5300" },
    { category_name: "Wig and Toupee Stores", category_value: "wig_and_toupee_stores", category_code: "5698" },
    { category_name: "Wires, Money Orders", category_value: "wires_money_orders", category_code: "4829" },
    { category_name: "Womens Accessory and Specialty Shops", category_value: "womens_accessory_and_specialty_shops", category_code: "5631" },
    { category_name: "Womens Ready-To-Wear Stores", category_value: "womens_ready_to_wear_stores", category_code: "5621" },
    { category_name: "Wrecking and Salvage Yards", category_value: "wrecking_and_salvage_yards", category_code: "5935" }
];
export default categories;