import React, { useEffect, useState, useRef } from "react";
import DataService from "../../../services/data.service";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";


const ViewProductDetails = (props) => {
    const form = React.useRef();
    const [bestDeal, setBestDeal] = useState('');
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [certifications, setCertifications] = useState([]);
    const images = props?.data?.images;
    
    useEffect(() => {
        if(props && props.data && props.data.certifications){
            setCertifications((props?.data?.certifications ? JSON.parse(props?.data?.certifications) : []));
        }
    },[props]);


    const onChangeStatus = (e) => {
        const data = e.target.value;
        setStatus(data);
        setDisabled(false);
    };
    const onChangeDeal = (event) => {
        const data = (event.target.checked)?'Yes':'No';
        setBestDeal(data);
        setDisabled(false);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const data = new FormData();
        if(bestDeal){
            data.append('best_deal',bestDeal);
        }
        if(status){
            data.append('status',status)
        }
       
        DataService.updateProduct(data, props?.data?.id).then(
            () => {
                setLoading(false);
                toast.success('Product updated successfully!', {
                    position: toast.POSITION.TOP_RIGHT
                });
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setLoading(false);
                toast.error(resMessage, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        );
    };
    return (
        <div className="row">
            <div className="col-md-12">
                <h3>Product Details</h3>
                <div className="row">
                    <div className="col-md-8">
                        <table class="table table-bordered tf-12 product-detail">
                            <thead class="table-secondary">
                                <tr>
                                    <th scope="col" class="f-700 w-50">Product</th>
                                    <th scope="col">{props.data.name}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="f-700">Category</td>
                                    <td>{props?.data?.category?.name}</td>
                                </tr>
                                <tr>
                                    <td class="f-700">Sub Category</td>
                                    <td>{props?.data?.subcategory?.name}</td>
                                </tr>
                                {/* <tr>
                                    <td class="f-700">Brand</td>
                                    <td>{props?.data?.Brand?.name}</td>
                                </tr> */}
                                <tr>
                                    <td class="f-700">SKU</td>
                                    <td>{props?.data?.sku}</td>
                                </tr>
                                {/* <tr>
                                    <td class="f-700">Product ID</td>
                                    <td>{props.data.product_id}</td>
                                </tr> */}
                                {/* <tr>
                                    <td class="f-700">Quantity</td>
                                    <td>{props.data.quantity}</td>
                                </tr> */}
                                {/* <tr>
                                    <td class="f-700">MRP</td>
                                    <td>${props?.data?.mrp}</td>
                                </tr> */}
                                <tr>
                                    <td class="f-700">Cost Price</td>
                                    <td>£ {props.data.cost_price}</td>
                                </tr>
                                {/* <tr>
                                    <td class="f-700">Offered price</td>
                                    <td>${props.data.offer_price}</td>
                                </tr> */}
                                {/* <tr>
                                    <td class="f-700">Certifications</td>
                                    <td>{(certifications.length > 0 ? certifications.join(",") : "-")}</td>
                                </tr> */}
                                <tr>
                                    <td class="f-700">Description</td>
                                    <td dangerouslySetInnerHTML={{ __html: props?.data?.details }}></td>
                                </tr>
                                <tr>
                                    <td class="f-700">Created At:</td>
                                    <td>{moment(props.data.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="row mt-5">
                            <div className="col-xl-3 col-md-5 col-12">
                            {/* <form onSubmit={handleSubmit} ref={form}> */}
                                    {/* <div class="mb-3">
                                        <label class="form-label"> Add product in best deals</label>
                                        <div class="form-check form-switch">
                                            <input 
                                            defaultChecked={props?.data?.best_deal==='Yes'? true:false}
                                            class="form-check-input" 
                                            type="checkbox"
                                            key={props?.data?.best_deal}
                                            onChange={onChangeDeal} 
                                            role="switch" 
                                             />                                             
                                        </div>
                                    </div> */}
                                    {/* <div class="mb-3">
                                        <label class="form-label">Change Status</label>
                                        <select key={props?.data?.status} defaultValue={props?.data?.status} className="form-select" onChange={onChangeStatus}>
                                            <option vlaue="Published">Published</option>
                                            <option vlaue="Pending">Pending</option>
                                            <option vlaue="Rejected">Rejected</option>
                                        </select>
                                    </div> */}

                                    {/* <div class="mb-3">
                                    <button disabled={disabled || loading} className="btn btn-primary">
                                            {loading && (
                                                <span className="spinner-border spinner-border-sm"></span>
                                            )}
                                            <span>Save</span>
                                        </button>
                                    </div> */}
                                {/* </form> */}
                            </div>


                        </div>
                    </div>

                    <div className="col-md-4">
                       <div className="bg-grey">
                        <div className="preview p-3 d-flex align-items-center justify-content-center">
                        {(props.data.file_path ? 
                                    <img width="200" src={"https://api.dealitt.com/"+props.data.file_path}  alt="product" />
                                : ""
                                )}
                        </div>
                        <ul className="img-thumb">
                        {images && images.length > 0
                                    ? images.map((item, i) => (
                                        <li>
                                            <img width="27"  src={"https://api.dealitt.com/"+item.file_path} alt='product'/>
                                        </li>
                                    )):""
                        }      
                        </ul>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewProductDetails;