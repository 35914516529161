import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./App.css";
import {HashRouter, Routes, Route } from "react-router-dom";
import Loginpage from "./pages/login";
import Dashboard from "./pages/dashboard";
import Otp from "./pages/otp";
import ForgotPassword from "./pages/forgot-password";
import AddProduct from "./pages/add-product";
import Product from "./pages/products";
import EditProduct from "./pages/edit-product";
import Orders from "./pages/orders";
import { RestrictedAccess } from "./private-component/RestrictedAccess";
import Brand from "./pages/brand";
import ViewBrand from "./pages/view-brand";
import AddNewBrand from "./pages/add-new-brands";
import SignUpFields from "./pages/signup-field";
import MyProfile from "./pages/myprofile";
import EditProfile from "./pages/edit-profile";
import Invoice from "./pages/invoice";
import ViewProduct from "./pages/view-product";
import ViewReview from "./pages/review";
import ReviewDetails from "./pages/review-detail";
import OrderDetails from "./pages/order-details";
import InvoiceDetails from "./pages/invoice-details";
import Forgot from "./pages/Forgot";
import Resetpassword from "./pages/reset-password";
import Notification from "./pages/notification";
import Company from "./pages/company";
import ManageProfile from "./pages/manage-profile";
import ManageCompany from "./section/home/Company/ManageCompany";
import EditCompany from "./section/home/Company/EditCompany";
import Chats from "./pages/Chats";
import Refund from "./pages/RefundRequest";
import CompanyAccount from "./pages/CompanyAccount";
export default function App() {
    return (
      <HashRouter onUpdate={() => window.scrollTo(0, 0)}>
        <Routes>
          <Route path="/" element={<Loginpage />}/>
            <Route exact path="/Login" element={<Loginpage />} />
            <Route exact path="/otp" element={<Otp />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/forgot" element={<Forgot/>}/>
            <Route path="/reset-password/:token" element={<Resetpassword/>} /> 
            <Route element={<RestrictedAccess />}>
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/add-product" element={<AddProduct />} />
            <Route exact path="/products" element={<Product />} />
            <Route exact path="/view-product/:id" element={<ViewProduct />} />
            <Route exact path="/edit-product/:id" element={<EditProduct />} />
            <Route exact path="/orders" element={<Orders />} />  
            <Route exact path="/refund-request" element={<Refund />} />
            <Route exact path="/company-account" element={<CompanyAccount />} />

            <Route exact path="/brand" element={<Brand />} />            
            <Route exact path="/brand-details/:id" element={<ViewBrand />} />            
            <Route exact path="/add-brand" element={<AddNewBrand />} /> 
            <Route exact path="/edit-brand/:id" element={<AddNewBrand />} />              
            <Route exact path="/sign-up-fields" element={<SignUpFields />} />          
            <Route exact path="/my-profile" element={<MyProfile />} />          
            <Route exact path="/edit-profile/:id" element={<MyProfile />} />                        
            <Route exact path="/invoice" element={<Invoice />} />          
            <Route exact path="/invoice-details/:id" element={<InvoiceDetails />} />  
            <Route exact path="/order-details/:id" element={<OrderDetails />} />          
            <Route exact path="/review" element={<ViewReview />} />          
            <Route exact path="/review-details/:id" element={<ReviewDetails />} />   
            <Route exact path="/notification" element={<Notification />} />  
            <Route exact path="/edit-company-profile" element={<Company/>} />
            <Route exact path="/manage-company-info" element={<ManageProfile/>} />  
            <Route exact path="/add-company" element={<ManageCompany/>}/>     
            <Route exact path="/edit-company" element={<EditCompany/>}/>   
            <Route exact path="/chats" element={<Chats/>}/>     
          </Route>
        </Routes>
      </HashRouter>
    );
  }